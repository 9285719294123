import { getPropsForHomepage } from "utils/staticHelpers";
import { HomePage } from "containers/HomePage";

export async function getStaticProps({ preview = false }) {
  return await getPropsForHomepage({ preview });
}

const PageToExport = (props) => <HomePage {...props} />;

export default PageToExport;
