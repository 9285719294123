import { Page } from "ineo/components/Page";
import { HomeHero } from "ineo/components/home/HomeHero";
import { HomeHub } from "ineo/components/home/HomeHub";
import { HomeDefinitions } from "ineo/components/home/HomeDefinitions";
import { HomeUpdates } from "ineo/components/home/HomeUpdates";
import { HomeAbout } from "ineo/components/home/HomeAbout";
import { CardsContainer } from "shared/components/CardsContainer";
import { MaxWidth } from "shared/components/MaxWidth";
import { LARGE } from "shared/utils/constants";
import { useAppContext } from "shared/utils/useAppContext";
import {
  PROJECT_OVERVIEW_TYPE,
  RESOURCE_FINDER_TYPE,
} from "ineo/utils/constants";
import { DEFINITION_TYPES } from "../utils/types";
import { createInternalHref } from "../utils/createInternalHref";

export const HomePage = ({
  title,
  intro,
  updates,
  about,
  footer,
  mirrorPath,
  navigation,
  seo,
  resourceTypes,
  definitions,
  projects,
  resources,
}) => {
  const { scope } = useAppContext();

  const definitionsLinks = definitions
    ? Object.entries(DEFINITION_TYPES).map(([type, { key, title }]) => ({
        title,
        key,
        href: createInternalHref({ type, href: "" }),
        items: definitions[key]?.map(({ index, slug, title }) => ({
          key: index,
          label: title,
          href: createInternalHref({
            type,
            href: `${slug}-${index}`,
          }),
        })),
      }))
    : [];

  return (
    <Page
      darkHeader
      description={seo?.description || intro}
      footer={footer}
      image={seo?.image}
      mirrorPath={mirrorPath}
      navigation={navigation}
      title={[seo?.title || scope, title].join(" - ")}
    >
      <HomeHero title={title} lead={intro} resourceTypes={resourceTypes} />
      <MaxWidth size={LARGE}>
        <HomeHub items={resourceTypes} />
        {definitions && (
          <HomeDefinitions
            title={definitions.title}
            lead={definitions.text}
            groups={definitionsLinks}
          />
        )}
        {resources && (
          <CardsContainer
            title={resources.title}
            lead={resources.text}
            link={resources.link}
            allLinkType={RESOURCE_FINDER_TYPE}
            items={resources.items}
          />
        )}
        {projects && (
          <CardsContainer
            title={projects.title}
            lead={projects.text}
            link={projects.link}
            allLinkType={PROJECT_OVERVIEW_TYPE}
            items={projects.items}
          />
        )}
        <HomeUpdates title={updates?.title} items={updates?.links} />
        <HomeAbout title={about?.title} link={about?.link} text={about?.text} />
      </MaxWidth>
    </Page>
  );
};
